@import "~antd/lib/style/themes/index.less";
@import "~hive-admin/src/antd.less";

@packman-primary: hsla(#F2C314);
@packman-primary-faded-85: hsla(@packman-primary, 0.85);
@packman-primary-faded-80: hsla(@packman-primary, 0.8);
@packman-black: #2D2C47;
@packman-black-faded: hsla(@packman-black, 0.8);

@primary-color: @packman-primary;

@heading-color: @packman-primary;
@text-color: @packman-black-faded;

.field-address {
  .field-google-map {
    position: relative;
    margin-top: 15px;
    border-radius: @border-radius-base;
    border: 1px solid @input-border-color;
    transition: all 0.3s @ease-in-out;
    overflow: hidden;
    &[data-disabled="false"]:hover {
      border: 1px solid @input-hover-border-color;
    }
    &[data-disabled="true"] .field-google-map-container {
      opacity: 0.3;
      pointer-events: none;
    }
  }
  .field-google-map-container {
    box-sizing: border-box;
    height: 300px;
  }
  .field-address-center-icon {
    pointer-events: none;
    font-size: 30px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -100%);
  }
}
