@import "~antd/lib/style/themes/index.less";
@import "~hive-admin/src/antd.less";

@packman-primary: hsla(#F2C314);
@packman-primary-faded-85: hsla(@packman-primary, 0.85);
@packman-primary-faded-80: hsla(@packman-primary, 0.8);
@packman-black: #2D2C47;
@packman-black-faded: hsla(@packman-black, 0.8);

@primary-color: @packman-primary;

@heading-color: @packman-primary;
@text-color: @packman-black-faded;

@includeLessVars: {
  display: none !important;
  @vars: primary-color,primary-1,primary-2,background-color-light,text-color,disabled-color,error-color,red-2,border-color-base,border-radius-base;
  .-(@i: length(@vars)) when (@i > 0)
  {
    @name: extract(@vars, @i);
    --@{name}: @@name !important;
    .-((@i - 1));
  }
  .-;
}

@import '~antd/lib/style/core/index.less';
@import '~antd/lib/style/components.less';

#__LESSVARS__ {
  @includeLessVars();
}

#admin #sidebar .ant-menu-item {
  &:after { display: none !important }
  border-radius: 10px;
}

#admin {
  > .ant-layout,
  > .ant-layout-content {
    margin-top: env(safe-area-inset-top);
    padding-bottom: env(safe-area-inset-bottom);
  }
  &[data-hide-header="false"] > #header {
    height: calc(80px + env(safe-area-inset-top));
    padding-top: env(safe-area-inset-top);
  }
  &[data-hide-sidebar="false"] > #sidebar {
    padding-top: calc(20px + env(safe-area-inset-top));
  }
  &[data-is-tablet="true"] {
    .ant-btn[data-action]:not([data-action="upload"]) {
      padding: 8px 26px;
      font-size: 116%;
      height: auto;
      letter-spacing: 0.5px;
      border-radius: 6px;
    }
    &[data-hide-floating="false"] {
      .ant-btn[data-action]:not([data-action="upload"]) {
        width: 85%;
      }
    }
  }
  &[data-is-mobile="true"]&[data-hide-floating="false"] {
    .ant-btn[data-action]:not([data-action="upload"]) {
      width: 75%;
    }
  }
  .page-header .page-header-left .page-header-title {
    @media (min-width: 769px) {
      font-size: 48px !important;
    }
  }
}

.ant-message {
  top: calc(16px + env(safe-area-inset-top));
}
